<template>
    <div class="videoDetailBox">
        <div class="videoDetailHeader" v-if="JSON.stringify(videoList)!='{}'">
            <div class="DetailHeaderLeft">
                <img v-if="videoList.video_details.author.avatar" class="headImg" :src="videoList.video_details.author.avatar" alt="">
                <div class="headerIdFs">
                    <p>{{videoList.video_details.author.nickname}}</p>
                    <p>{{videoList.video_details.author.total_favorited}}粉丝</p>
                </div>
            </div>
            <div class="DetailHeaderright">
                <div>
                    <p>总点赞</p>
                    <p>{{videoList.video_details.author.follower_count}}</p>
                </div>
                <div class="xian"></div>
                <div>
                    <p>总评论</p>
                    <p>{{videoList.video_details.author.following_count}}</p>
                </div>
                <div class="xian"></div>
                <div>
                    <p>总转发</p>
                    <p>{{videoList.video_details.author.following_count}}</p>
                </div>
            </div>
        </div>
        <!-- <div class="DetailComment">
            <div class="DetailCommentTile">
                <span>热门评论</span>
                <span>({{videoList.video_comments?videoList.video_comments.length : 0}})</span>
            </div>
            <div class="DetailCommentList">
                <div class="DetailCommentItem" v-for="(item,index) in videoList.video_comments" :key="index">
                    <div style="margin-bottom: 15px">
                        <p>{{item.text}}</p>
                        <p>{{item.create_time}}</p>
                    </div>
                    <div style="display: flex;align-items: center;margin-right: 45px">
                        <i class="iconfont">&#xe85c;</i>
                        <span style="margin-left: 9px">{{item.digg_count}}</span>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>
<script>
    export default {
        props:{
            videoList:{
                type:Object
            }
        },
        data(){
            return {
                comment_lists:[],
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        created() {
            this.comment_lists = this.videoList.comment_lists;
        },
        mounted() {
        },
        methods:{
            updateData() {
                this.comment_lists = this.videoList.comment_lists
            },
        }
    }
</script>
<style scoped lang="scss">
    .videoDetailBox{
        /*overflow: scroll;*/
        overflow-y: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        .videoDetailHeader{
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            .DetailHeaderLeft{
                margin-left: 35px;
                display: flex;
                .headImg{
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                }
                .headerIdFs{
                    p{
                        margin: 0;
                        margin-left: 16px;
                    }
                    p:nth-child(1){
                        color: #333333;
                        font-size: 16px;
                    }
                    p:nth-child(2){
                        color: #333333;
                        font-size: 14px;
                    }
                }
            }
            .DetailHeaderright{
                display: flex;
                align-items: center;
                text-align: center;
                .xian{
                    width: 1px;
                    height: 30px;
                    background: #EAEAEA;
                }
                div{
                    margin-right: 40px;
                    p{
                        line-height: 1;
                        margin: 0;
                    }
                    p:nth-child(2){
                        margin-top: 8px;
                    }
                }
            }
        }
        .DetailComment{
            margin-top: 62px;
            .DetailCommentTile{
                span:nth-child(1){
                    color: #333333;
                    font-size: 16px;
                    margin-left: 32px;
                }
                span:nth-child(2){
                    color: #999999;
                    font-size: 16px;
                }
            }
        }
        .DetailCommentList{
            margin-top: 10px;
            margin-left: 40px;
            .DetailCommentItem{
                position: relative;
                margin-right: 26px;
                border-bottom: 1px solid #EAEAEA;
                display: flex;
                justify-content: space-between;
                div{
                    i{
                        color: #999999;
                        font-size: 17px;
                    }
                    p:nth-child(1){
                        color: #333333;
                        font-size: 14px;
                        line-height: 1;
                        margin: 0;
                        margin-top: 14px;
                    }
                    p:nth-child(2){
                        color: #999999;
                        font-size: 14px;
                        line-height: 1;
                        margin: 0;
                        margin-top: 11px;
                    }
                }
                div:nth-child(2){
                    position: absolute;
                    left: 90%;
                    top: 40%;
                }
            }
        }
    }
</style>