<template>
    <div class="videoAnalyze">
        <div class="left_box">
            <div class="gender-box">
                <span class="title">性别分布</span>
                <div class="gender-chart">
                    <div class="icon-box">
                        <i class="iconfont" style="font-size: 20px">&#xe6a6;</i>
                        <i class="iconfont" style="font-size: 20px">&#xe6a8;</i>
                    </div>
                    <div class="chart-content">
                        <div class="man" :style="{width: this.man+'%'}"></div>
                        <div class="women"></div>
                    </div>
                    <div class="bottom-box">
                        <div class="box1">
                            <span>男</span>
                            <span>{{this.man}}%</span>
                        </div>
                        <div class="box1">
                            <span>女</span>
                            <span>{{this.woman}}%</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="age-distribution">
                <span class="title">年龄分布</span>
                <div id="ageDistribution" style="width: 500px;height: 275px;"></div>
            </div>
        </div>
        <div class="right_box">
            <div class="title">
                <span>地区分布</span>
                <span style="float: right;">
                    <el-radio-group v-model="radio" size="small" @change="getListByCh">
                        <el-radio-button label="0">省份</el-radio-button>
                        <el-radio-button label="1">城市</el-radio-button>
                    </el-radio-group>
                </span>
            </div>
            <div class="area-distribution">
                <div class="title">
                    <span>名称</span>
                    <span>占比</span>
                </div>
                <div class="content">
                    <div class="area-item" v-for="item in areaData">
                        <span class="area-name">{{item.areaName}}</span>
                        <el-progress :percentage="item.percentage" :show-text="false" :stroke-width="12"></el-progress>
                        <span class="percentage">{{item.percent}}%</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export  default {
        props:{
            videoList:{
                type:Object
            }
        },
        data(){
            return {
                radio: 0,
                tableData: [],
                genderData: {},
                areaData: [],
                man: 0,
                woman: 0,
            }
        },
        created() {
        },
        mounted(){
            this.ageDistribution = this.$echarts.init(document.getElementById('ageDistribution'));
            // window.onresize = () => {
            //     this.ageDistributionFun();
            // };
        },
        methods: {
            updateData() {
                this.radio = 0;
                this.areaDataFun(this.videoList.video_fansData.province_summary);
                let person = this.videoList.video_fansData.gender_summary;
                let ages = this.videoList.video_fansData.age_summary;
                let man = person[0].count;
                let woman = person[1].count;
                let num =man+woman;
                this.man = Math.ceil((man/num)*100);
                this.woman = Math.floor((woman/num)*100);
                this.ageDistributionFun(ages);
            },
            //省份/城市
            getListByCh() {
                if (this.radio == 0) {
                    this.areaData = [];
                    this.areaDataFun(this.videoList.video_fansData.province_summary);
                } else {
                    this.areaData = [];
                    this.areaDataFun(this.videoList.video_fansData.city_summary);
                }
            },
            areaDataFun(arry) {
                //地区数据处理
                this.areaData = [];
                let sum = arry[0].count;
                let sums = 0;
                for (let i in arry) {
                    sums += arry[i].count;
                }
                if(arry.length>10){
                    for(let j = 0; j <10; j++){
                        let obj = {
                            areaName: arry[j].keyword,
                            percentage: Math.round(arry[j].count / sum * 10000) / 100.00,
                            percent: Math.round(arry[j].count / sums * 10000) / 100.00
                        };
                        this.areaData.push(obj);
                    }
                } else {
                    for (let i in arry) {
                        let obj = {
                            areaName: arry[i].keyword,
                            percentage: Math.round(arry[i].count / sum * 10000) / 100.00,
                            percent: Math.round(arry[i].count / sums * 10000) / 100.00
                        };
                        this.areaData.push(obj);
                    }
                }
            },
            ageDistributionFun(array) {
                //年龄段数据处理
                let ageArray = [], dataArray = [];
                for (let i in array) {
                    ageArray.push(array[i].keyword);
                    let obj = {
                        value: array[i].count,
                        name: array[i].keyword
                    };
                    dataArray.push(obj)
                }
                ageArray.sort();
                this.ageDistribution = this.$echarts.init(document.getElementById('ageDistribution'));
                this.ageDistribution.setOption({
                    color: {
                        type: 'linear',
                        x: 0.5,
                        y: 0.5,
                        r: 0.5,
                        colorStops: [{
                            offset: 1, color: '#25C495' // 0% 处的颜色
                        }, {
                            offset: 0, color: '#039167' // 100% 处的颜色
                        }],
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: ageArray,
                            axisTick: {
                                alignWithLabel: true
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "#ccc"
                                }
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            axisLine:{       //y轴
                                show:false
                            },
                            axisTick:{       //y轴刻度线
                                show:false
                            },
                            splitLine: {     //网格线
                                show: false
                            },
                            axisLabel:{
                                formatter:function () {
                                    return""
                                }
                            }
                        }
                    ],
                    series: [
                        {
                            name: '',
                            type: 'bar',
                            barWidth: '30%',
                            itemStyle: {        //上方显示数值
                                normal: {
                                    label: {
                                        show: true, //开启显示
                                        position: 'top', //在上方显示
                                        textStyle: { //数值样式
                                            color: '#ccc',
                                            fontSize: 16
                                        }
                                    }
                                }
                            },
                            data: dataArray,
                        }
                    ]
                },true);
            },
        }
    }
</script>
<style scoped lang="scss">
    .videoAnalyze{
        height: 100%;
        display: flex;
        justify-content: space-between;
        .left_box {
            display: flex;
            flex-direction: column;
            width: 50%;
            height: 100%;
            border-right: 1px solid #eaeaea;
            padding: 0 20px 20% 20px;

            .gender-box {
                display: flex;
                flex-direction: column;
                margin-bottom: 68px;

                .gender-chart {
                    margin-top: 24px;
                    .icon-box {
                        display: flex;
                        justify-content: space-between;
                        color: #34aa7f;
                    }

                    .chart-content {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        height: 16px;
                        margin-top: 20px;
                        .man {
                            background-color: #34aa7f;
                        }

                        .women {
                            flex: 1;
                            background-color: #46dbab;
                        }
                    }

                    .bottom-box {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #666;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 20px;
                        .box1 {
                            display: flex;
                            flex-direction: column;
                        }
                        .box1:nth-child(1){
                            align-items: end;
                            span:nth-child(2){
                                margin-top: 8px;
                            }
                        }
                        .box1:nth-child(2){
                            align-items: flex-end;
                            span:nth-child(2){
                                margin-top: 8px;
                            }
                        }
                    }
                }
            }

            .age-distribution {
                width: 100%;
                .title {
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                }
            }
        }
        .right_box {
            display: flex;
            flex-direction: column;
            width: 50%;
            height: 100%;
            padding: 0 20px;
            overflow: auto;
            .area-distribution {
                flex: 1;
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                height: calc(100% - 66px);

                .title {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 15px;
                    padding-right: 5px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333;
                }

                .content {
                    flex: 1;
                    overflow: auto;
                    padding-right: 5px;
                    .area-item:nth-child(1){
                        margin-top: 20px;
                    }
                }

                .area-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 35px;

                    ::v-deep .el-progress {
                        width: 80%;
                        padding: 0 10px;
                    }

                    ::v-deep .el-progress-bar__inner {
                        background: linear-gradient(45deg, #ffa72a, #f8b95f 98%);
                    }
                }
            }
        }
    }
</style>